























































import { Component, Mixins, Prop } from 'vue-property-decorator';

import listAccordion from '@/mixins/listAccordion';

import { ContentHistoric } from '@/globalInterfaces/Book';
import { FileVideo, FileCheckedStatus } from '../../interface';

const VideoListLoading = () => import('../VideoList/VideoListLoading.vue');
const FeedbackUser = () => import('@/components/FeedbackUser/index.vue');
const FilterButton = () => import('../FilterButton/index.vue');
const FilterClass = () => import('../FilterClass/FilterClass.vue');
const VideoList = () => import('../VideoList/index.vue');

interface ListFiles {
  files: Array<FileVideo>;
  group: number;
}

interface GroupVideoStatus {
  [key: number]: boolean;
}

@Component({
  components: {
    VideoListLoading,
    FeedbackUser,
    FilterButton,
    FilterClass,
    VideoList,
  },
})
export default class WrapperVideoList extends Mixins(listAccordion) {
  @Prop({ required: true }) loadingVideosList!: boolean;
  @Prop({ required: true }) currentChecked!: boolean;
  @Prop({ required: true }) list!: ContentHistoric;
  @Prop({ required: true }) newListGroupVideos!: Array<ListFiles>;
  @Prop({ required: true }) allChecked!: Array<FileCheckedStatus>;

  private showFilter = false;

  get visibilityVideoGroups() {
    const visibilityVideoGroups: Array<GroupVideoStatus> = [];

    this.newListGroupVideos.forEach((item: ListFiles) => {
      const verify = item.files.every((video: FileVideo) => video.hidden === true);

      visibilityVideoGroups.push({ [item.group]: verify });

    });

    return visibilityVideoGroups;
  }

  closeFilter() {
    if (!this.showFilter) return;

    this.showFilter = !this.showFilter;
  }

  changeFilterStatus() {
    this.showFilter = !this.showFilter;
  }

  emitClickVideoFromList() {
    this.$emit('click-video-list');
  }
}
